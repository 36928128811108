/*
 * @Author: codeshitmeshit
 * @Date: 2023-07-05 20:18:27
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-07-08 14:09:26
 * @FilePath: \webapp\src\router\index.js
 * @Description: 
 * codeshitmeshit
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/MainPage',
    name: 'MainPage',
    component: () => import('../webapp/MainPage.vue')
  },

  {
    path: '/',
    redirect: '/MainPage'
  },


  //查询类路由
  {
    path: '/search/ComprehensiveQuery',
    name: 'ComprehensiveQuery',
    component: () => import('../webapp/Search/ComprehensiveQuery.vue')
  },
  {
    path: '/search/OtherSearch',
    name: 'OtherSearch',
    component: () => import('../webapp/Search/OtherSearch.vue')
  },


  //历史类路由
  {
    path: '/history/OperateLog',
    name: 'OperateLog',
    component: () => import('../webapp/History/OperateLog.vue')
  },
  {
    path: '/history/SearchData',
    name: 'SearchData',
    component: () => import('../webapp/History/SearchData.vue')
  },
  {
    path: '/history/SearchHistory',
    name: 'SearchHistory',
    component: () => import('../webapp/History/SearchHistory.vue')
  },

  //数据类路由
  {
    path: '/data/AddData',
    name: 'AddData',
    component: () => import('../webapp/Data/AddData.vue')
  },
  {
    path: '/data/DataQuery',
    name: 'DataQuery',
    component: () => import('../webapp/Data/DataQuery.vue')
  },
  {
    path: '/data/UserData',
    name: 'UserData',
    component: () => import('../webapp/Data/UserData.vue')
  },

  //图片类路由
  {
    path: '/Pic/PicHistory',
    name: 'PicHistory',
    component: () => import('../webapp/Pic/PicList.vue')
  },
  {
    path: '/Pic/PicUpload',
    name: 'PicUpload',
    component: () => import('../webapp/Pic/PicUpload.vue')
  },

  //爬虫类路由
  {
    path: '/Trend/BaiduHot',
    name: 'Trending',
    component: () => import('../webapp/spider/HotTrending.vue')
  },
  {
    path: '/Trend/WeiboHot',
    name: 'Trending',
    component: () => import('../webapp/spider/WeiboTrending.vue')
  },
  {
    path: '/Trend/BilibiliTop',
    name: 'Trending',
    component: () => import('../webapp/spider/BilibiliTop.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
