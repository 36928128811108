<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-07-05 20:18:27
 * @LastEditors: cosh codeshitmeshit@126.com
 * @LastEditTime: 2023-08-21 09:24:31
 * @FilePath: \webapp\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app"  style="height: 99%; width: 99%; position : absolute;">
    <el-container  style="height: 99%; width: 99%; position : absolute;">
      <el-header>
        <div id="PageHead" style="height: 10%;"></div>
      </el-header>
      <!-- 侧边栏 -->
      <el-container>
          <div id="PageAside" style="height: 90%;"></div>
        <el-container>
          <!-- 主要页面编写 -->
          <el-main>
            <div style="height: 90%;">
              <router-view></router-view>a
            </div>
          </el-main>
          <!-- 脚注 -->
          <el-footer>
              <div id="PageFooter"></div>  
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
  </div>
</template>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}


</style>

<script>
export default {
  mounted() {
      this.getMounted()
    },
    methods: {
      getMounted(){
        let _this = this
        this.$axios.get('/mainMounted').then(res => {
        let data = res.data.data
        for(let i = 0 ; i < data.length; i ++)
        {
          _this.notice(data[i].from , data[i].msg , i)
        }
      })
      },
      notice(from , msg , i){
        this.$notify.info({
          dangerouslyUseHTMLString: true,
          title: from,
          message: msg,
          offset: 0 + 150 * i
        });
      }
    }
}
</script>