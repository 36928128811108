<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-07-05 20:18:27
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-07-11 22:06:45
 * @FilePath: \webapp\src\webapp\Mode\PageFooter.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div>
        <el-footer style="height: 30px;">
            <div style="float:left"><font style="font-size: small;">版本号:V3.0</font></div>
        <!-- <div style="display: inline"></div> -->
            <div style="display: inline;"><i class="el-icon-ice-cream"></i><el-link href="https://blog.bugbuilder.top/console" target="_blank">去博客记bug QAQ</el-link><i class="el-icon-ice-cream"></i></div>
            <div id='setting'>
            <el-button type="primary" icon="el-icon-setting" style="height: 30px; text-align: center; background-color: #5d99e752;"></el-button>
            </div>
        </el-footer>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.el-footer {
    font-family: "Hiragino Sans GB";
    font-size: 20px;
    background-color: #5d99e752;
    color: #333;
    text-align: center;
    font-weight: 700;
}

#setting {
    text-align: right;
    display: inline;
    float: right;
    height: 600;
    }

</style>