import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MainPage from "./webapp/MainPage.vue"
import PageHead from "./webapp/Mode/PageHead.vue"
import PageAside from "./webapp/Mode/PageAside.vue"
import PageFooter from "./webapp/Mode/PageFooter.vue"
import axios from 'axios'

Vue.prototype.$axios = axios

//引入element-UI组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

axios.defaults.baseURL = '/api'


Vue.use(ElementUI)
Vue.config.productionTip = false

Vue.prototype.Log = function (thing , kind){
  this.$axios.get('/Log/add',{
    params: {
              thing:thing,
              kind:kind,
          }
  })
}


Vue.prototype.SearchLog = function (context, kind, is, url){
  this.$axios.get('/search/AddLog',{
    params: {
              context:context,
              kind:kind,
              is:is,
              url:url
          }
  })
}


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')


new Vue({
  router,
  render: h => h(MainPage)
}).$mount('#MainPage')


new Vue({
  router,
  render: h => h(PageHead)
}).$mount('#PageHead')


new Vue({
  router,
  render: h => h(PageAside)
}).$mount('#PageAside')


new Vue({
  router,
  render: h => h(PageFooter)
}).$mount('#PageFooter')



