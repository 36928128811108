<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-07-05 20:18:27
 * @LastEditors: cosh codeshitmeshit@126.com
 * @LastEditTime: 2023-08-21 09:11:51
 * @FilePath: \webapp\src\webapp\MainPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div style="height: 100%; height: 100%;">

  </div>
</template>

<script>
export default {
    data() {
      return {
        dialogVisible: false,
        src: 'https://cosh.oss-cn-beijing.aliyuncs.com/555.jpg'
      }
  },
  methods: {
  }
  }
</script>

<style scoped>
</style>