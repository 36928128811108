<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-07-05 20:18:27
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-07-08 14:52:32
 * @FilePath: \webapp\src\webapp\Mode\PageHead.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div style="width: 100%;">
        <el-header>
            <div style="display: inline;float: left">
              <el-button type="primary" style="height: auto; text-align: center; background-color: transparent;">
                <router-link to="/MainPage" tag="el-button" style=" background-color: transparent;"><i class="el-icon-s-home" style="color: black;"></i><font style="font-size:larger; color: black;">主页</font> </router-link>
              </el-button>
            </div>
            <div style="display: inline;">今天也一定要努力摸鱼丫！</div>
            <div style="display: inline;text-align: right; float: right;">
                <input id="time" disabled/>
            </div>
        </el-header>
    </div>
</template>

<script>

export default {
  data () {
    return {
        timer: ''
      }
    },
    mounted(){
      let _this = this
      // eslint-disable-next-line no-unused-vars
      let timer = setInterval(() => {
     //需要定时执行的代码
      _this.datetime();
      },1000)
    },
    methods:{
      datetime()
      {
        let now = new Date();
        document.getElementById("time").value = now.getFullYear() + "-"
            + (now.getMonth() + 1) + "-" + now.getDate();
        document.getElementById("time").value += " " + now.getHours() + ":"
            + now.getMinutes() + ":" + now.getSeconds();
      }
    }
    
}
</script>

<style scoped>

.el-header{
    background-color: transparent; 
    font-family: "Hiragino Sans GB";
    font-size: 30px;
    /* background-color: #5d99e7; */
    color: #333;
    text-align: center;
    line-height: 60px;
    font-weight: 1000;
  }


#time {
    background-color: #5d99e752;
    text-align: right;
    color: #333;
    width: 120px;
    font-weight: 700;
    }

.el-button {
  border: 0;
  background-color: transparent;
}
    
</style>